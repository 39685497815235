
.card-chart {
    height: 100%;
    margin-bottom: 0 !important;
    word-wrap: inherit !important;
    background-color: #f4f4f4 !important;
    border: 1px solid #c7e0ff !important;
    border-left: 6px solid #c7e0ff !important;
}

/*.card {*/
/*    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 #bacce2;*/
/*    -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 #bacce2;*/
/*    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 #bacce2;*/
/*    -moz-border-radius: 2px;*/
/*    -webkit-border-radius: 2px;*/
/*    border-radius: .5rem;*/
/*    background: #ffffff;*/
/*    !*padding: 1.25em;*!*/
/*    margin-bottom: 1em;*/
/*    box-sizing: border-box;*/
/*}*/
/*.card .card-title:first-child {*/
/*    margin-top: 0;*/
/*}*/
.card-chart .card-title {
    font-size: 14px;
    font-weight: 600;
    /*margin: 2em 0 1em 0;*/
    letter-spacing: -0.6px;
}
/*.card .card-title {
    font-size: 16px;
    font-weight: 600;
    margin: 2em 0 1em 0;
    letter-spacing: 0.3px;
}*/

.connection-chart {
    text-align: center;
    /*padding-top: 2em !important;*/
    /*padding-right: 0 !important;*/
    /*padding-left: 0 !important;*/
}

.connection-chart-legend {
    padding-left: 4% !important;
    padding-top: 1.5em !important;
    color: #616161;
}

.card-chart .connection-chart-legend .p-grid{
    margin-right: -0.2em !important;
    margin-left: -0.2em !important;
    margin-top: -0.2em !important;
}

.dot {
    margin-right: 0.5em;
    vertical-align: middle;
    height: 1em;
    width: 1em;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
}

.card-element{
    padding: 0 0.5em 0.5em 0.5em !important;
}

.p-col-7{
    margin-left: -25px;
    margin-right: -20px;
}

