.comment {
    position: relative;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    margin-bottom: 10px;
    margin-left: 15px;
    padding: 0;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.comment-content{
    padding: 10px;
}

.comment-header{
    border-bottom: 1px solid #c8c8c8;
    background-color: #f5f4f4;
    color: #333333;
    padding: 0.5em;
    font-weight: 600;
    border-bottom: 0 none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.comment-header:before{
    position:absolute;
    top:6px;left:-24px;
    right:100%;
    width:0;
    height:0;
    display:block;
    content:" ";
    border-color:transparent;
    border-style:solid solid outset;
    pointer-events:none;
}

.comment-header:before{
    border-right-color:#ddd;
    border-width:12px;
}