@media screen and (min-width: 1367px) {
    body .p-component {
        font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
        line-height: 1.2 !important;
        font-size: 12px !important;
    }

    .p-inputtext{
        font-size: 12px !important;
    }

    .col-form-label-sm {
        line-height: 1.2 !important;
        font-size: 12px !important;
    }

    .p-multiselect{
        width: 100% !important;
        font-size: 12px !important;
    }

    .form-control{
        height: calc(1.65rem) !important;
        font-size: 12px !important;
        padding: .0rem .75rem !important;
    }

    .layout-wrapper .layout-sidebar ul li a {
        font-size: 12px !important;
    }

    .btn{
        line-height: 1 !important;
        font-size: 13px !important;
    }

    /*.p-panel-titlebar{*/
    /*    background-color: #2196F3 !important;*/
    /*    border: 1px solid #2196F3;*/
    /*    !*background-color: #78909c !important;*!*/
    /*    !*border: 1px solid #78909c;*!*/
    /*    color: #ffffff !important;*/
    /*    font-size: 13px !important;*/
    /*    font-weight: bold !important;*/
    /*    padding: 1em !important;*/
    /*    border-radius: 8px 8px 0 0 !important;*/
    /*}*/

    .p-fieldset .p-fieldset-legend {
        font-size: 13px !important;
    }

    .p-fieldset .p-fieldset-legend-text {
        font-size: 13px !important;
    }
}

@media screen and (max-width: 1366px) {
    body .p-component {
        font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
        line-height: 1 !important;
        font-size: 10px !important;
    }

    .p-inputtext{
        font-size: 10px !important;
    }

    .col-form-label-sm {
        line-height: 1 !important;
        font-size: 10px !important;
    }

    .p-multiselect{
        width: 100% !important;
        font-size: 10px !important;
    }

    .form-control{
        height: calc(1.65rem) !important;
        font-size: 10px !important;
        padding: .0rem .75rem !important;
    }

    .layout-wrapper .layout-sidebar ul li a {
        font-size: 10px !important;
    }

    .btn{
        line-height: 1 !important;
        font-size: 11px !important;
    }

    /*.p-panel-titlebar{*/
    /*    background-color: #2196F3 !important;*/
    /*    border: 1px solid #2196F3;*/
    /*    !*background-color: #78909c !important;*!*/
    /*    !*border: 1px solid #78909c;*!*/
    /*    color: #ffffff !important;*/
    /*    font-size: 11px !important;*/
    /*    font-weight: bold !important;*/
    /*    padding: 1em !important;*/
    /*    border-radius: 8px 8px 0 0 !important;*/
    /*}*/

    .p-fieldset .p-fieldset-legend {
        font-size: 11px !important;
    }

    .p-fieldset .p-fieldset-legend-text {
        font-size: 11px !important;
    }
}

.MuiPaper-rounded {
    border-radius: 15px !important;
}

.MuiInputLabel-outlined {
    background-color: #fff !important;
}

/*body .p-component {*/
/*    line-height: 1.2 !important;*/
/*    font-size: calc(0.2em + 1vmin) !important;*/
/*}*/

.upload-img {
    border: 3px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    max-width: 60%;
    height: auto;
}

.upload-img:hover {
    box-shadow: 0 0 3px 2px rgba(0, 140, 186, 0.5);
}

.datatable-img {
    border: 3px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    max-height: 100px !important;
    height: auto;
}

.datatable-img:hover {
    box-shadow: 0 0 3px 2px rgba(0, 140, 186, 0.5);
}

.p-grid {
    margin-right: 0 !important;
    margin-top: 0 !important;
}

/*.p-toolbar{*/
/*    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);*/
/*}*/

.p-toolbar{
    background: #f8f9fa !important;
    border: 1px solid #dee2e6 !important;
    border-radius: 6px !important;
    gap: 0.5rem !important;
    padding: 0.571em 1em !important;
}

.p-green{
    color: green !important;
}

.p-red{
    color: green !important;
}

.layout-wrapper .layout-main {
    /*background-color: #edf5ff !important;*/
    background-color: #eff3f8 !important;
    /*background-color: #f6f8fc !important;*/
    padding: 80px 10px 16px 10px !important;
}

/*.layout-wrapper .layout-topbar{*/
/*    box-shadow: 0 1px 10px rgba(0,0,0,.12),0 4px 5px rgba(0,0,0,.14),0 2px 4px -1px rgba(0,0,0,.2)!important;*/
/*    !*box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);*!*/
/*    -webkit-box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);*/
/*    -moz-box-shadow: 0 1px 2px 4px rgba(0, 0, 0, 0.3);*/
/*    background: linear-gradient(to right, #f3f4f9 0%, #f8f9fa 100%) !important;*/
/*}*/

.layout-topbar {
    border-bottom: 2px solid #dfe7ef !important;
    background: none !important;
    backdrop-filter: blur(8px) !important;
    /*height: 60px !important;*/
}

/*.layout-topbar {*/
/*    background: #ffffff !important;*/
/*    box-shadow: rgb(0 0 0 / 3%) 0px 4px 10px, rgb(0 0 0 / 6%) 0px 0px 2px, rgb(0 0 0 / 12%) 0px 2px 6px !important;*/
/*}*/

.layout-wrapper .layout-sidebar .layout-logo {
    margin-top: 0 !important;
}

.layout-sidebar {
    /*background: #edf5ff !important;*/
    background: #eff3f8 !important;
    /*background: #f6f8fc !important;*/
}

.layout-topbar a {
    color: #000000 !important;
}



/*body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight {*/
/*    background-color: #39a3f4 !important;*/
/*    color: #ffffff !important;*/
/*}*/

/*.p-panel, .p-fieldset{*/
/*    background-color: #ffffff;*/
/*    color: #333333;*/
/*    !*box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);*!*/
/*    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 30%);*/
/*    -webkit-border-radius: 10px !important;*/
/*    border-radius: 12px !important;*/
/*}*/


.p-panel .p-panel-titlebar {
    border: 1px solid #c8c8c8 !important;
    padding: 0.857rem 1rem !important;
    color: #333333 !important;
    background-color: #ffffff !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    /*background: #f4f4f4 !important;*/
    /*border-top-right-radius: 3px !important;*/
    /*border-top-left-radius: 3px !important;*/
}

.login-background{
    position: absolute;
    width: 100%;
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/*.p-dialog-mask{*/
/*    z-index: 999 !important;*/
/*}*/


.p-datatable-tbody * {
    /*border: 0px !important;*/
    /*border-top: 1px #e5e5e5 solid !important;*/
    text-overflow: ellipsis !important;
    overflow-x: hidden !important;
    /* white-space: nowrap; */
}

.p-datatable-resizable .p-datatable-thead>tr>th {
    overflow: unset !important;
    text-align: initial !important;
}

.p-datatable .p-datatable-footer, .p-datatable .p-datatable-header,
.p-datatable .p-datatable-tfoot>tr>td, .p-datatable .p-datatable-thead>tr>th,
.p-paginator{
    background-color: #f8f9fa !important;
    border: 1px solid #dee2e6  !important;
}

.p-datatable .p-datatable-tbody>tr>td {
    background: inherit !important;
    border: 1px solid #dee2e6 !important;
    padding: 0.9em 0.5em !important;
}

body .p-datatable .p-sortable-column.p-highlight {
    background-color: #007ad9 !important;
}

.p-datatable .p-datatable-header {
    height: 50px !important;
}

.p-datatable-resizable .p-datatable-thead>tr>th, .p-datatable-resizable .p-datatable-tfoot>tr>td, .p-datatable-resizable .p-datatable-tbody>tr>td {
    overflow: unset !important;
    white-space: normal !important;
}

.p-datatable-resizable .p-datatable-tbody>tr>td{
    overflow: hidden !important;
}

.p-datatable .p-column-header-content {
    display: inline-block !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    margin-left: 0 !important;
}

.p-datatable-resizable-fit .p-resizable-column:last-child .p-column-resizer{
    display:none !important;
}

.p-datatable table {
    border-collapse: collapse !important;
    width: 100% !important;
    table-layout: fixed !important;
}

.p-unselectable-text{
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -o-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}


.p-fieldset .p-fieldset-legend a {
    padding: 0.571em 1em !important;
}

.p-fieldset .p-fieldset-legend {
    font-size: 13px !important;
    font-weight: bold !important;
    margin: 0 !important;
    width: auto !important;
    color: #007bff;
    padding: 0.571em 1em !important;
    background: #ffffff !important;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0 !important;
}

.p-fieldset .p-fieldset-legend-text {
    color: #007bff;
}

body .p-dropdown .p-dropdown-trigger {
    width: 2em;
}

.p-datatable-footer {
    text-align: center;
    font-weight: 700 !important;
}

.p-column-filter-clear-button {
    width: 0px !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0.6rem 1em !important;
}

.p-dialog .p-dialog-header {
    padding: 0.5rem !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 12px !important;
}

.disabled {
    opacity: .5;
}

.p-panel-header {
    background-color: var(--surface-section) !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}

.p-datatable-loading-content{
    position:absolute !important;
    left:50% !important;
    top:50% !important;
    z-index:2 !important;
    margin-top:-1em !important;
    margin-left:-1em !important;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 0;
    padding: 0.429rem 0.857rem;
    border: 0 none;
    color: #333333;
    background: none !important;
    transition: background-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
}


/*.p-datatable-loading-overlay{*/
/*    position:absolute !important;*/
/*    width:100% !important;*/
/*    height:100% !important;*/
/*    -ms-filter:"alpha(opacity=10) !important";*/
/*    opacity:.1 !important;*/
/*    z-index:1 !important;*/
/*}*/

/*.p-datatable-loading-content{*/
/*    position:absolute !important;*/
/*    left:50% !important;*/
/*    top:50% !important;*/
/*    z-index:2 !important;*/
/*    margin-top:-1em !important;*/
/*    margin-left:-1em !important;*/
/*    opacity:1 !important;*/
/*}*/
/*.p-datatable .p-datatable-loading-icon{*/
/*    font-size:2em !important;*/
/*    opacity: revert !important;*/
/*}*/


/*.p-editable-column *{*/
/*overflow: auto !important;*/
/*}*/

/*.p-datatable-thead * {*/
/*!*border: 0px !important;*!*/
/*!*border-top: 1px #e5e5e5 solid !important;*!*/
/*text-overflow: ellipsis !important;*/
/*overflow: hidden !important;*/
/*}*/

.p-datatable-row {
    border-right: 1px solid #e9ecef !important;
    border-left: 1px solid #e9ecef !important;
}

.p-dropdown {
    min-width: auto !important;
}


.btn-primary, .btn-secondary, .btn-success, .btn-danger, .btn-info{
    margin-right: 10px !important;
    max-height: 29px;
}

/* DateInput */
.react-datepicker-wrapper, .react-datepicker__input-container{
    width: 100%;
}


.p-fieldset{
    margin-bottom: 0.6rem;
}

label {
    font-weight: bold !important;
}

.p-dropdown-label{
    margin-bottom: 0 !important;
}


.p-multiselect-label {
    font-weight: 400 !important;
}

.form-group .p-disabled {
    background-color: #e9ecef !important;
    opacity: 1 !important;
}

.my-multiselected-item-token {
    padding: .0rem .75rem !important;
    display: inline-block;
    vertical-align: middle;
    height: 1.857em;
    border-radius: 3px;
}

.p-steps .p-steps-item {
    width: auto !important;
}

.p-steps .p-steps-item .p-menuitem-link {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 1em;
    background: transparent !important;
}

.form-group {
    margin-bottom: 0.5rem !important;
}

label {
    margin-bottom: .25rem !important;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}



.textAreaHermes {
    height: 100px !important;
}

.textAreaHermesBig {
    min-height: 175px !important;
}

.textAreaHermesSoBig {
    min-height: 280px !important;
}

.textAreaCloseDialog {
    height: 200px !important;
}


.selectTableButton{
    position: absolute;
    right: -10px;
    top: 0;
    margin: 0;
    height: 100%;
}

.selectTableButtonForBlock{
    position: absolute;
    right: 6px;
    top: 0;
    margin: 0;
    height: 100%;
}

.selectTableButton2{
    position: absolute;
    right: 25px;
    top: 0;
    margin: 0;
    height: 100%;
}

.selectTablePlusButton25{
    position: absolute;
    right: 25px;
    top: 0;
    margin: 0;
    height: 100%;
}

.selectTablePlusButton60{
    position: absolute;
    right: 60px;
    top: 0;
    margin: 0;
    height: 100%;
}

.captchaButton {
    width:100%;
}

.highlightTableRow{
    background-color: #fb6161f5  !important;
}

.highlightTableRowPending{
    background-color: rgb(175, 255, 112) !important;
}

.warningTableRow{
    background-color: #F9A825 !important;
}

.selectControlButton{
    position: absolute;
    right: 27px;
    top: 0;
    margin: 0;
    height: 100%;
}

.displayNone{
    display: none !important;
}

.footerToolBar label{
    width: 89px;
    float: right;
    /* border-right: 1px solid; */
    padding: 0 21px;
}

.pivot-datatables-class .p-datatable-wrapper{
    width: 100% !important;
    overflow: auto !important;
    overflow-y: scroll;
    border-right: 1px solid #c8c8c8;
}

.pivot-datatables-class .p-datatable table {
    border-collapse: collapse;
    width: 100%;
    table-layout: initial;
}


.status {
    border-radius: 3px !important;
    padding: 0.25em 0.5rem !important;
    letter-spacing: .3px !important;
    /*text-transform: uppercase;*/
    font-weight: 600 !important;
    /*font-size: 11px !important;*/
}

.status-INSTALLED, .status-OPEN{
    background-color: #b3e5fc !important;
    color: #23547b !important;
}

.status-A, .status-Success, .status-ACTIVE, .status-RESOLVED, .status-SEND {
    background-color: #c8e6c9 !important;
    color: #256029 !important;
}
.status-P, .status-Error, .status-UNINSTALLED, .status-CANCELLED, .status-FAILED{
    background-color: #ffcdd2 !important;
    color: #c63737 !important;
}

.status-AWAITING_APPROVAL {
    background: #feedaf;
    color: #8a5340;
}

/*.status-AWAITING_APPROVAL {*/
/*    background-color: #ffd8b2;*/
/*    color: #805b36;*/
/*}*/

.status-SUSPENDED, .status-PENDING {
    background-color: #eccfff;
    color: #694382;
}

/*.p-datatable.p-datatable-custom {*/
/*    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);*/
/*}*/
/*.p-datatable.p-datatable-custom .p-datatable-header {*/
/*    border: 0 none;*/
/*    padding: 12px;*/
/*    text-align: left;*/
/*    font-size: 20px;*/
/*}*/
/*.p-datatable.p-datatable-custom .p-paginator {*/
/*    border: 0 none;*/
/*    padding: 1em;*/
/*}*/
/*.p-datatable.p-datatable-custom .p-datatable-thead > tr > th {*/
/*    border: 0 none;*/
/*    text-align: left;*/
/*}*/
/*.p-datatable.p-datatable-custom .p-datatable-thead > tr > th.p-filter-column {*/
/*    border-top: 1px solid #c8c8c8;*/
/*}*/
/*.p-datatable.p-datatable-custom .p-datatable-tbody > tr > td {*/
/*    border: 0 none;*/
/*    cursor: auto;*/
/*}*/
/*.p-datatable.p-datatable-custom .p-dropdown-label:not(.p-placeholder) {*/
/*    text-transform: uppercase;*/
/*}*/








/*.dashboard .overview-box {*/
/*    text-align: center;*/
/*    color: #ffffff;*/
/*    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);*/
/*    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);*/
/*    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);*/
/*}*/
/*.dashboard .overview-box .overview-box-header {*/
/*    height: 24px;*/
/*}*/
/*.dashboard .overview-box .overview-box-content {*/
/*    padding: 8px 14px 14px 14px;*/
/*}*/
/*.dashboard .overview-box .overview-box-content .overview-box-icon {*/
/*    -moz-border-radius: 50%;*/
/*    -webkit-border-radius: 50%;*/
/*    border-radius: 50%;*/
/*    width: 40px;*/
/*    height: 40px;*/
/*    line-height: 40px;*/
/*    margin: 0 auto;*/
/*    margin-top: -28px;*/
/*}*/
/*.dashboard .overview-box .overview-box-content .overview-box-icon i {*/
/*    line-height: inherit;*/
/*    font-size: 28px;*/
/*}*/
/*.dashboard .overview-box .overview-box-content .overview-box-title {*/
/*    font-size: 14px;*/
/*}*/
/*.dashboard .overview-box .overview-box-content .overview-box-count {*/
/*    font-size: 24px;*/
/*}*/
/*.dashboard .overview-box.overview-box-1 .overview-box-header {*/
/*    background-color: #f06292;*/
/*}*/
/*.dashboard .overview-box.overview-box-1 .overview-box-content {*/
/*    background-color: #e91e63;*/
/*}*/
/*.dashboard .overview-box.overview-box-1 .overview-box-content .overview-box-icon {*/
/*    background-color: #e91e63;*/
/*}*/
/*.dashboard .overview-box.overview-box-2 .overview-box-header {*/
/*    background-color: #4dd0e1;*/
/*}*/
/*.dashboard .overview-box.overview-box-2 .overview-box-content {*/
/*    background-color: #00bcd4;*/
/*}*/
/*.dashboard .overview-box.overview-box-2 .overview-box-content .overview-box-icon {*/
/*    background-color: #00bcd4;*/
/*}*/
/*.dashboard .overview-box.overview-box-3 .overview-box-header {*/
/*    background-color: #ffd54f;*/
/*}*/
/*.dashboard .overview-box.overview-box-3 .overview-box-content {*/
/*    background-color: #ffc107;*/
/*}*/
/*.dashboard .overview-box.overview-box-3 .overview-box-content .overview-box-icon {*/
/*    background-color: #ffc107;*/
/*}*/
/*.dashboard .overview-box.overview-box-4 .overview-box-header {*/
/*    background-color: #9e9e9e;*/
/*}*/
/*.dashboard .overview-box.overview-box-4 .overview-box-content {*/
/*    background-color: #616161;*/
/*}*/
/*.dashboard .overview-box.overview-box-4 .overview-box-content .overview-box-icon {*/
/*    background-color: #616161;*/
/*}*/

/*.dashboard .timeline {*/
/*    box-sizing: border-box;*/
/*    margin-top: 0;*/
/*}*/
/*.dashboard .timeline > .p-grid .p-col-3 {*/
/*    font-size: 14px;*/
/*    position: relative;*/
/*    border-right: 1px solid #dddddd;*/
/*}*/
/*.dashboard .timeline > .p-grid .p-col-3 i {*/
/*    font-size: 24px;*/
/*    position: absolute;*/
/*    top: 6px;*/
/*    right: -12px;*/
/*}*/
/*.dashboard .timeline > .p-grid .p-col-9 {*/
/*    padding-left: 1.5em;*/
/*}*/
/*.dashboard .timeline > .p-grid .p-col-9 .event-text {*/
/*    color: #7a7a7a;*/
/*    font-size: 14px;*/
/*    display: block;*/
/*    padding-bottom: 20px;*/
/*}*/
/*.dashboard .timeline > .p-grid .p-col-9 .event-content img {*/
/*    width: 100%;*/
/*}*/