.card-overview {
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
    -moz-border-radius: 24px;
    -webkit-border-radius: 24px;
    border-radius: 24px;
    border: 2px solid #c7e0ff !important;
    cursor: pointer;
}

.card-overview:hover {
    color: #494949 !important;
    border-radius: 50px;
    border-color: #494949 !important;
    transition: all 0.3s ease 0s;
}

.card-overview a:link, a:visited, a:hover, a:active {
    text-decoration: none !important;
}

.overview-box {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    padding-top: 24px !important;
    height: 100% !important;
    min-width: 150px !important;
}

/*.overview-box.white {*/
/*    background: #FFFFFF;*/
/*    color: rgba(41, 50, 65, 0.8);*/
/*}*/